








































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.text_area_for_localization {
  width: 100%;
  height: 120px;
  line-height: 1.7 !important;
  padding: 10px 15px;
  resize: none;
  color: #606266;
  width: 100%;
  border: 1px solid #dcdfe6;
  outline: none;
  transition: 0.3s;
  border-radius: 4px;
  word-break: break-word;
  &:focus {
    border-color: #11bfe3;
  }
  &::placeholder {
    color: rgba(#606266, 0.5);
    font-size: 14px;
    font-weight: 400;
  }
}
::v-deep .card label {
  text-transform: capitalize !important;
}

::v-deep .card label {
  text-transform: capitalize;
  margin-bottom: 0;
}

::v-deep .el-form {
  display: flex;
  flex-wrap: wrap;
  gap: 1%;
}

::v-deep .el-form-item__content {
  // background: #ccc;
  padding: 0;
}

.main_wrapping_of_inputs {
  width: 24% !important;
  &.long {
    width: 50% !important;
  }
  &.with_heading {
    width: 100% !important;
    text-align: center;
    text-transform: uppercase;
    box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
    .heading_separator {
      margin: 0 !important;
      padding: 12px 0;
      // font-size: 35px;
      // font-weight: bold;
      // letter-spacing: 5px;
      color: #777;
      // text-shadow: 1px 0px 1px #ccc, 0px 1px 1px #eee, 2px 1px 1px #ccc,
      //   1px 2px 1px #eee, 3px 2px 1px #ccc, 2px 3px 1px #eee, 4px 3px 1px #ccc,
      //   3px 4px 1px #eee, 5px 4px 1px #ccc, 4px 5px 1px #eee, 6px 5px 1px #ccc,
      //   5px 6px 1px #eee, 7px 6px 1px #ccc;
    }
  }
  // .heading_separator {
  //   color: red;
  //   background: red;
  // }
}

.tabs_button_rapper {
  gap: 10px;
  .tabs_button {
    border: none;
    border-bottom: 2px solid transparent;
    background: none;
    min-width: 70px;
    transition: 0.3s;
    background: #eee;
    &:hover,
    &.active {
      border-bottom: 2px solid green;
    }
  }
}
// .more_text {
//   height: 70px;
//   background: #eee;
// }
// ::v-deep .el-input__inner {
//   word-wrap: break-word;
//   white-space: pre-wrap;
// }
.label_custom_img {
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  margin-bottom: 10px;
  transition: 0.3s all;
  background-color: #11bfe3;
  min-width: 144px;
  position: relative;
  height: 37px;
  &:hover {
    opacity: 0.8;
  }
  &.processing {
    pointer-events: none;
  }
  .loader_ocr {
    position: absolute;
    width: 25px;
    height: 25px;
    margin-bottom: 7px;
    left: calc(50% - 12.5px);
    top: 15%;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: rotation 0.7s linear infinite;
    border-color: #fff #fff #fff transparent;
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.export_import_buttons_wrapper {
  display: flex;
  justify-content: end;
  gap: 10px;
  padding: 0 0.7rem;
  .btn_wrapper {
    // border: 1px solid #ccc;
    border: none;
    border-radius: 3px;
    background-color: #eee;
    transition: 0.3s;
    color: #777;

    &.export {
      &:hover {
        background-color: #11bfe3;
        color: #fff;
      }
    }
    &.import {
      &:hover {
        color: #fff;
        background-color: #3c3;
      }
    }
  }
  .localization_icon {
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
  }
}
